import { Controller } from '@hotwired/stimulus';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default class extends Controller {
  static targets = ['menu', 'icon'];

  toggle() {
    this.menuTarget.classList.contains('-is-active')
      ? this.close()
      : this.open();
  }

  open() {
    const scrollContainerEl = this.element.querySelector(
      '[data-scroll-container]'
    );

    this.menuTarget.classList.add('-is-active');
    this.iconTarget.classList.add('nc-int-icon-state-b');

    this.menuTarget.addEventListener('turbo:click', this.close);
    scrollContainerEl.scrollTop = 0;

    disableBodyScroll(scrollContainerEl);
    this.dispatch('opened');
  }

  close = (e) => {
    if (e) {
      if (e.target.id === 'platformSwitchMobile') return;
    }
    this.menuTarget.classList.remove('-is-active');
    this.iconTarget.classList.remove('nc-int-icon-state-b');
    clearAllBodyScrollLocks();

    this.menuTarget.removeEventListener('turbo:click', this.close);
    this.dispatch('closed');
  };
}
